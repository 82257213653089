import React from "react";
import "../components/payments.css";
import correoafi from "../images/correoafiliado.webp";
import panelmp from "../images/panelmp.jpg";
import newprice from "../images/01-seller25.jpg";
import marchprice from "../images/marchprices.png";
import Writehorario from "./Horario";

const PaySeller = () => {
  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      /* ✅ Copied successfully */
    } catch (e) {
      /* ❌ Failed to copy (insufficient permissions) */
    }
  }

  const writehora = () => {
    return (
      <>
        <div className="contenthora">
          <h5>🕘 Horario de atención 🕘</h5>
          <div className="contentatencion">
            <b>👇Atención UNICAMENTE dentro del horario👇</b>
          </div>
          <div>
            Lunes y viernes
            <br />
            9:30 a 11:30{/*  - 17:00 a 20:00 */}
            <br />
          </div>
          <div>
            Martes a jueves
            <br />
            9:30 a 13:00{/*  - 17:00 a 20:00 */}
            <br />
            <b>Sabados, domingos y feriados: CERRADO</b>
          </div>
        </div>
      </>
    );
  };
  const writeatencion = () => {
    return (
      <>
        <div className="contentatencion">
          <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
        </div>
      </>
    );
  };

  return (
    <>
      {/* {writehora()} */}
      <Writehorario />
      {writeatencion()}
      <h3>Novedades</h3>
      <div className="row">
        <div class="card border-primary col-md-6" /* style="max-width: 18rem;" */>
          <div class="card-header">Atención: Nuevo precio</div>
          <div class="card-body bg-primary">
            <h5 class="card-title text-white">
              Para cuentas finales: $ 8000,00!
            </h5>
            <p class="card-text text-light bg-dark">
              A partir de marzo el precio de nuestra app de T.v D1git4l, será de $ 8000/mes.<br />
              La app de contenido extra será opcional y, estará incluida en el costo mencionado.<br />
              Como reseller recibirá las mismas fich4s que compre para la app principal.
            </p>
          </div>
        </div>
        <div class="card border-primary col-md-6" /* style="max-width: 18rem;" */>
          <div class="card-header">Desea mensionar el servicio en redes?</div>
          <div class="card-body bg-success">
            <h5 class="card-title text-black">
              Si desea mencionar nuestra app en redes
            </h5>
            <p class="card-text text-warning">
              Puede hacerlo siempre y cuando{" "}<b>no se nombre fen1x ni su log0 , ni mund1al 7v ni su l0go, ni
                mul7ivisi0n play ni su l0go</b>{" "}y en lo posible{" "}<b>NO HAGAN REFERENCIA A LOS CAN4LES QUE TRAE ni el contenido.</b><br/>
                Pude decir: dele vida a su smat convertidor android con TV DIGITAL, PLAY TV, tv por internet,
              tv online etc; asi sí puede llamarlas
              <br />Y Recuerde! hacer publicidad moderadamente y sin mencionar
              nombre ni mostrar logos. Solo menciones genericas.
            </p>
          </div>
        </div>
       
      </div>
      <h3>Creditos Panels</h3>
      <div className="contentprecio">
        <span className="divprecio">Elija plan y solicite por cantidad</span>
        junto con el mail de su panel:
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Precios actuales</h5>
              <p class="card-text">Precios actuales </p>
              <img class="img" src={newprice} alt="Card image cap" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Precios marzo</h5>
              <p class="card-text">Precios a partir de marzo '25</p>
              <img class="img" src={marchprice} alt="Card image cap" />
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "lightgray" }}>Medios de pago</div>
      <div className="contentalias">
        <div>
          💸 <span className="cuentas">Lemon</span>: neura.norma.lemon
          <button
            className="boton"
            onClick={() => copyToClipboard("neura.norma.lemon")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Prex</span>: 27952878.prex
          <button
            className="boton"
            onClick={() => copyToClipboard("27952878.prex")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Uala</span>: enegraso.uala
          <button
            className="boton"
            onClick={() => copyToClipboard("enegraso.uala")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Belo</span>: fedeveloperbelo
          <button
            className="boton"
            onClick={() => copyToClipboard("fedebeloperbelo")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Personal</span>: fedeveloperppay
          <button
            className="boton"
            onClick={() => copyToClipboard("fedeveloperppay")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Claro</span>: fedevelopercpay
          <button
            className="boton"
            onClick={() => copyToClipboard("fedevolopercpay")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">YPF</span>: fedevelopypf
          <button
            className="boton"
            onClick={() => copyToClipboard("fedevelopypf")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">MercadoPago</span>: fedeveloper
          <button
            className="boton"
            onClick={() => copyToClipboard("fedeveloper")}
          >
            Copiar
          </button>
        </div>
        <div>
          🏦 <span className="cuentas">Naranja</span>: fedevelopernx
          <button
            className="boton"
            onClick={() => copyToClipboard("fedevelopernx")}
          >
            Copiar
          </button>
        </div>
        <div>
          🏦 <span className="cuentas">Provincia</span>: fedeveloperdni
          <button
            className="boton"
            onClick={() => copyToClipboard("fedeveloperdni")}
          >
            Copiar
          </button>
        </div>
      </div>
      <div className="contentprecio">
        Puede copiar el alias de su preferencia y luego pegarlo en su app de
        pago
        <br />
        <b>Debe enviar el comprobante una vez realizado el pago</b>
      </div>
      <br />
      <Writehorario />
      {/*     {writehora()}
       */}{" "}
      {writeatencion()}
    </>
  );
};

export default PaySeller;

const Writehorario = () => {
    return (<>
        <div className='contenthora'>
            <h5>🕘 Horario de atención 🕘</h5>
            <div className='contentatencion'>
                <b>👇Atención UNICAMENTE dentro del horario👇</b>
            </div>
            <div>Lunes y viernes<br />
                10:00 a 11:30 - 17:00 a 19:00<br />
            </div>
            <div>Martes a jueves<br />
                10:00 a 12:30 - 17:00 a 19:00<br />
                <b>Sabados, domingos y feriados: CERRADO</b></div>
        </div>
    </>)
}

export default Writehorario
import React from "react";
import "../components/payments.css";
import Writehorario from "./Horario";
import precios from "../images/preciosapp.png";

const Payments = () => {
  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      /* ✅ Copied successfully */
    } catch (e) {
      /* ❌ Failed to copy (insufficient permissions) */
    }
  }

  const writehora = () => {
    return (
      <>
        <div className="contenthora">
          <h5>🕘 Horario de atención 🕘</h5>
          <div className="contentatencion">
            <b>👇Atención UNICAMENTE dentro del horario👇</b>
          </div>
          <div>
            Lunes y viernes
            <br />
            9:30 a 11:30 - 17:00 a 20:00
            <br />
          </div>
          <div>
            Martes a jueves
            <br />
            9:30 a 13:00 - 17:00 a 20:00
            <br />
            <b>Sabados, domingos y feriados: CERRADO</b>
          </div>
        </div>
      </>
    );
  };

  const writedecember = () => {
    return (
      <>
        <div className="contenthora">
          <div className="row">
            <h5>💵 Precio actual y futuro</h5>
            <div className="card col-md-6">
              <b>app 📺:💲5000,00, si deseas la app 🐦‍🔥🎬 el costo total será:💲7000,00</b>
            </div>
            <div className="card col-md-6">
              <b>
                ⚠️ A partir de marzo 2025 app 📺 + app 🐦‍🔥🎬 el costo único💲8000,00
              </b>
            </div>
          </div>
          <div>🤝 Muchas gracias!</div>
        </div>
      </>
    );
  };

  const writeatencion = () => {
    return (
      <>
        <div className="contentatencion">
          <b>☝️Atención UNICAMENTE dentro del horario☝️</b>
        </div>
      </>
    );
  };

  return (
    <>
      {/*     {writehora()} */} <Writehorario />
      {writeatencion()}
      <hr />
      {writedecember()}
      <h1>Métodos de pago correo S2K</h1>
      <div className="row">
        <div className="card col-md-6">
          <span className="divprecio text-">
            Importe app ♾️ t3le: 💵 $ 5.000,00 (cinco mil pesos)
          </span>
        </div>
        <div className="card col-md-6">
          <span className="divprecio">
            Importe ♾️ t3le + la app 🐦‍🔥🎬: 💵 $ 7.000,00 (siete mil pesos)
          </span>
        </div>
      </div>
      <div className="text-warning">
        puede transferirlo a cualquiera de los siguientes alias:
      </div>
      <div className="contentalias">
        <div>
          💸 <span className="cuentas">Lemon</span>: neura.norma.lemon
          <button
            className="boton"
            onClick={() => copyToClipboard("neura.norma.lemon")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Prex</span>: 27952878.prex
          <button
            className="boton"
            onClick={() => copyToClipboard("27952878.prex")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Uala</span>: enegraso.uala
          <button
            className="boton"
            onClick={() => copyToClipboard("enegraso.uala")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Belo</span>: fedeveloperbelo
          <button
            className="boton"
            onClick={() => copyToClipboard("fedebeloperbelo")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Personal</span>: fedeveloperppay
          <button
            className="boton"
            onClick={() => copyToClipboard("fedeveloperppay")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">Claro</span>: fedevelopercpay
          <button
            className="boton"
            onClick={() => copyToClipboard("fedevolopercpay")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">YPF</span>: fedevelopypf
          <button
            className="boton"
            onClick={() => copyToClipboard("fedevelopypf")}
          >
            Copiar
          </button>
        </div>
        <div>
          💸 <span className="cuentas">MercadoPago</span>: fedeveloper
          <button
            className="boton"
            onClick={() => copyToClipboard("fedeveloper")}
          >
            Copiar
          </button>
        </div>
        <div>
          🏦 <span className="cuentas">Naranja</span>: fedevelopernx
          <button
            className="boton"
            onClick={() => copyToClipboard("fedevelopernx")}
          >
            Copiar
          </button>
        </div>
        <div>
          🏦 <span className="cuentas">Provincia</span>: fedeveloperdni
          <button
            className="boton"
            onClick={() => copyToClipboard("fedeveloperdni")}
          >
            Copiar
          </button>
        </div>
      </div>
      <div className="contentprecio">
        Puede copiar el alias de su preferencia y luego pegarlo en su app de
        pago
        <br />
        <b>Debe enviar el comprobante una vez realizado el pago</b>
      </div>
      <br />
      <div style={{ backgroundColor: "lightgray" }}>
        En efectivo 💵 $ 5.000,00 (cinco mil pesos) en <b>Rapipago</b> a cuenta{" "}
        <b>PREX: 10408748</b>
      </div>
      <div style={{ backgroundColor: "lightgray" }}>
        En efectivo 💵 $ 7.000,00 (siete mil pesos) en <b>Rapipago</b> a cuenta{" "}
        <b>PREX: 10408748</b>
      </div>
      <br />
      <div className="contenthora">
        <div>
          Link de pago:
          <br />
          incluye comisiones de pasarela de pago
        </div>
        <div>
          <b>Mercado Pago:</b>
          <button
            className="boton"
            onClick={() => window.open("https://mpago.la/1k8gaNt")}
          >
            Pagar
          </button>
        </div>
        <div>
          <b>Mercado Pago:</b>
          <button
            className="boton"
            onClick={() => window.open("https://mpago.la/16r1RM8")}
          >
            Pagar
          </button>
        </div>
        <div>Presione le botón Pagar para ir a la pasarela de pago</div>
      </div>
      <br />
      {writedecember()}
      <hr />
      {/*     {writehora()} */} <Writehorario />
      {writeatencion()}
    </>
  );
};

export default Payments;

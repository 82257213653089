import axios from "axios"
import { useEffect, useState } from "react";

const jsonbtvlocu = "https://inliveserver.com:2000/json/stream/12000";
const jsonbtv = "https://cp.panelchs.com:2020/json/stream/8206";
const jsoneps = "https://cp.panelchs.com:2020/json/stream/8146";


const Stats = () => {
  const [viewbtvlc, setViewbtvlc] = useState(null);
  const [vieweps, setVieweps] = useState(null);
  const [viewbtv, setViewbtv] = useState(null);

  useEffect(() => {
    axios.get(jsonbtvlocu).then((response) => {
      setViewbtvlc(response.data);
    });
    axios.get(jsonbtv).then((response) => {
      setViewbtv(response.data);
    });
    axios.get(jsoneps).then((response) => {
      setVieweps(response.data);
    });

  }, []);

  if (!viewbtvlc) return null;
  if (!viewbtv) return null;
  if (!vieweps) return null;

  return (
    <div>
      <div>
        <h1>Bragado TV</h1>
        <p>Server: {viewbtv.status === true ? "Habilitado" : "deshabilitado"}, Viendo: {viewbtv.connections}, Bitrate {viewbtv.bitrate}</p>
      </div>
      <div>
        <h1>Bragado TV lc</h1>
        <p>Server: {viewbtvlc.status === true ? "Habilitado" : "deshabilitado"}, Viendo: {viewbtvlc.connections}, Bitrate {viewbtvlc.bitrate}</p>
      </div>
      <div>
        <h1>EPS TV</h1>
        <p>Server: {vieweps.status === true ? "Habilitado" : "deshabilitado"}, Viendo: {vieweps.connections}, Bitrate {vieweps.bitrate}</p>
      </div>
    </div>
  );

}

export default Stats